import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'

import Button from '../components/Button/Button'
import Hr from '../components/Hr'
import H4 from '../components/H4'
import H5 from '../components/H5'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Contrato</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Termos do Serviço</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>Nossos Termos de Serviço define regras gerais que devem ser seguidas se você tiver uma conta de hospedagem de qualquer tipo com a gente, incluindo servidores dedicados ou colocated.</Paragraph>
                <Button
                  onClick={() => navigate('/termos-de-servico')}
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['260px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                    marginTop: 30
                  }}
                >
                  Clique aqui para ler
                </Button>
              </Div>
            </Column>
          </Row>
          <Div 
            alignItems={['center']}
          >
            <Hr
              lineColor='lightPeriwinkle'
              m={['0']}
              maxWidth={[328, '100%' ,680 ,680]}
              style={{
                opacity: 0.5
              }}
            />
          </Div>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <H5>Política de Privacidade</H5>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Paragraph>Privacidade é importante para HostDime. Em nossa Política de Privacidade lhe informamos quais informações coletamos quando você usa nossos produtos e serviços, porque nós coletamos, e como guardamos isso.</Paragraph>
                <Button
                  onClick={() => navigate('/politica-de-privacidade')}
                  schema='secondary'
                  color='white'
                  variant='highEmphasis'
                  width={['100%']}
                  maxWidth={['260px']}
                  style={{
                    display: 'flex',
                    border: 'none',
                    margin: '0 auto',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
                    marginTop: 30
                  }}
                >
                  Clique aqui para ler
                </Button>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Contrato' />
      <PageTemplate
        title='Contrato'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
